<template>
  <div class="flex flex-nowrap justify-end space-x-2">
    <template v-for="action in actions">
      <pp-themed-button
        v-if="action.external"
        :key="action.title"
        tag="a"
        target="_blank"
        :href="action.value"
        :variant="action.variant"
        :disabled="action.disabled"
        data-test="swap-button"
      >
        {{ action.title }}
      </pp-themed-button>

      <pp-themed-button
        v-else
        :key="action.title"
        tag="router-link"
        :to="action.value"
        :variant="action.variant"
        :disabled="action.disabled"
        :data-test="`${action.title.toLowerCase()}-button`"
      >
        {{ action.title }}
      </pp-themed-button>
    </template>
  </div>
</template>

<script>
  export default {
    props: {
      actions: { type: Array, default: () => [] },
    },
    data() {
      return {
        expandContext: false,
      }
    },
    methods: {
      handleFocusOut(event) {
        this.expandContext = event.currentTarget.contains(event.relatedTarget)
      },
    },
  }
</script>
