import PendleAmmMarket from '@/domains/entities/PendleAmmMarket'
import PendleMarket from '@/domains/entities/PendleMarket'

type YtOtMarket = PendleMarket | PendleAmmMarket

const queryMarket = (tokenType: 'yt' | 'ot' | 'combined'): YtOtMarket[] => {
  switch(tokenType) {
    case 'yt': return PendleAmmMarket.query()
    case 'ot': return PendleMarket.query().otMarkets
    case 'combined' : return PendleMarket.query()
    default: throw 'invalid tokenType'
  }
}

export const groupMarketByPairName = (markets: YtOtMarket[], expired: boolean): YtOtMarket[][] => {
  const groups = markets
    .filter(market => (market.status === 'expired') === expired)
    .reduce<Record<string, YtOtMarket[]>>((a, b) => {
      const key = b.pairName
      a[key] = a[key] || []
      a[key].push(b)
      return a
    }, {})

  return Object.values(groups)
}

export const groupMarketByExpiry = (markets: YtOtMarket[], expired: boolean) => {
  const byPairName = groupMarketByPairName(markets, expired)
  const otMarketGroups = byPairName.slice(0, byPairName.length / 2)
  const ytMarketGroups = byPairName.slice(byPairName.length / 2)
  return { otMarketGroups, ytMarketGroups }
}

export const getMarketTableData = ({ tokenType, expired = false }: {
  tokenType: 'yt' | 'ot' | 'combined',
  expired: boolean
}) => {
  const markets = queryMarket(tokenType)
  switch(tokenType) {
    case 'yt':
    case 'ot': return groupMarketByPairName(markets, expired)
    case 'combined' : return groupMarketByExpiry(markets, expired)
    default: throw 'invalid tokenType'
  }
}
