import { sdkChainId } from '@/app-config/network'
import PendleAmmMarket from '@/domains/entities/PendleAmmMarket'
import PendleMarket from '@/domains/entities/PendleMarket'

const getMainnetActions = (market: PendleMarket) => {
  const params = {
    tokenAddress1: market.tokenIssuer.yt.address,
    tokenAddress2: market.quoteToken.address,
  }
  const routeName = market.isExpired ? 'Redeem' : 'Mint'
  return [
    { title: routeName, value: { name: routeName, params } },
    {
      title: 'Swap',
      value: `https://app.sushi.com/swap?outputCurrency=${market.ot.address}&inputCurrency=${market.quoteToken.address}`,
      external: true,
      variant: 'sushi',
      disabled: market.isExpired,
    },
  ]
}

const getAvalancheActions = (market: PendleMarket) => {
  const params = {
    tokenAddress1: market.tokenIssuer.yt.address,
    tokenAddress2: market.quoteToken.address,
  }
  const routeName = market.isExpired ? 'Redeem' : 'Mint'
  return [
    { title: routeName, value: { name: routeName, params } },
    {
      title: 'Swap',
      value: `https://traderjoexyz.com/trade?outputCurrency=${market.ot.address}&inputCurrency=${market.quoteToken.address}`,
      external: true,
      variant: 'traderjoe',
      disabled: market.isExpired,
    },
  ]
}

const getYtMarketTableActions = (market: PendleAmmMarket) => {
  const [tokenAddress1, tokenAddress2] = market.pair
  const params = { tokenAddress1, tokenAddress2 }
  const routeName = market.isExpired ? 'Redeem' : 'Mint'
  return [
    { title: routeName, value: { name: routeName, params } },
    { title: 'Swap', value: { name: 'Swap', params }, disabled: market.isExpired },
  ]
}

const getOtMarketTableActions = (market: PendleMarket) => {
  const config: Record<number, any> = {
    1: getMainnetActions(market),
    31337: getMainnetActions(market),
    43114: getAvalancheActions(market),
  }

  return config[sdkChainId]
}

export const getMarketTableActions = (
  market: PendleMarket | PendleAmmMarket,
  tokenType: 'yt' | 'ot'
) => {
  switch (tokenType) {
    case 'yt':
      return getYtMarketTableActions(market as PendleAmmMarket)
    case 'ot':
      return getOtMarketTableActions(market as PendleMarket)
    default:
      throw 'invalid tokenType'
  }
}
