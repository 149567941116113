<template>
  <pp-card
    class="shadow-pendle overflow-x-auto lg:overflow-visible"
    :class="expired && 'bg-water-100'">
    <pp-table class="min-w-[800px] min-h-[200px] overflow-visible">
      <thead>
        <pp-tr class="whitespace-nowrap">
          <pp-th>Pool</pp-th>

          <pp-th align="right">Expiry</pp-th>

          <pp-th align="right">
            <div class="inline-flex items-center -mr-6">
              Underlying value
              <pp-tooltip-trigger direction="bottom">
                Market price of underlying asset.
                <br />
                <br />
                1 YT represent the yield for this amount of underlying.
                <br />
                <br />
                1 OT is redeemable to this amount at the end of expiry.
              </pp-tooltip-trigger>
            </div>
            <div class="text-sm font-medium">Underlying yield</div>
          </pp-th>

          <pp-th align="right">
            <div>Price</div>
            <div class="inline-flex items-center text-sm font-medium -mr-6">
              Implied yield
              <pp-tooltip-trigger direction="bottom">
                The annual yield on the underlying asset that the market is implying, by trading
                {{ tokenType.toUpperCase() }}
                at the current price.
              </pp-tooltip-trigger>
            </div>
          </pp-th>

          <pp-th align="right">Liquidity</pp-th>

          <pp-th filler></pp-th>
        </pp-tr>
      </thead>

      <tbody v-if="tokenType === 'combined'">
        <template v-for="(ytMarkets, groupIndex) in marketGroups.ytMarketGroups">
          <template v-for="(ytMarket, index) in ytMarkets">
            <MarketTableItem
              :key="ytMarket.address"
              tokenType="yt"
              combined
              :rowspan="2"
              :market="ytMarket"
              :actions="getActions(ytMarket, 'yt')"
            />
            <MarketTableItem
              :key="marketGroups.otMarketGroups[groupIndex][index].address"
              tokenType="ot"
              combined
              :market="marketGroups.otMarketGroups[groupIndex][index]"
              :actions="getActions(marketGroups.otMarketGroups[groupIndex][index], 'ot')"
            />
          </template>
        </template>
      </tbody>

      <tbody v-else-if="marketGroups.length > 0">
        <template v-for="markets in marketGroups">
          <MarketTableItem
            v-for="(market, index) in markets"
            :key="market.address"
            :tokenType="tokenType"
            :rowspan="index === 0 ? markets.length : undefined"
            :market="market"
            :actions="getActions(market, tokenType)"
          />
        </template>
      </tbody>

      <tbody v-else>
        <pp-tr>
          <pp-td class="text-center text-p-blue-500" colspan="6">
            <div>No markets available</div>
          </pp-td>
        </pp-tr>
      </tbody>
    </pp-table>
  </pp-card>
</template>

<script>
  import MarketTableItem from './MarketTableItem.vue'
  import { getMarketTableActions } from './uiHelper'

  export default {
    props: {
      tokenType: { type: String, required: true },
      marketGroups: { type: [Object, Array], required: true },
      expired: { type: Boolean, default: false }
    },
    components: {
      MarketTableItem,
    },
    methods: {
      getActions(market, tokenType) {
        return getMarketTableActions(market, tokenType)
      },
    },
  }
</script>
