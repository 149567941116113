<template>
  <pp-tr class="text-sm whitespace-nowrap">
    <pp-td v-if="combined || rowspan" :rowspan="!combined && rowspan" :class="border">
      <pp-market-preview
        :address="market.address"
        :data-test="`${market.address.toLowerCase()}-info`"
      ></pp-market-preview>
    </pp-td>

    <pp-td
      v-if="!combined || rowspan"
      :rowspan="combined && rowspan"
      :class="border"
      class="align-middle"
      align="right"
    >
      <div>
        <pp-formatted-date
          :value="expiry"
          :data-test="`${market.address.toLowerCase()}-expiry`"
        ></pp-formatted-date>
      </div>
      <pp-market-status-indicator :address="market.address"></pp-market-status-indicator>
    </pp-td>

    <pp-td
      v-if="!combined || rowspan"
      :rowspan="combined && rowspan"
      align="right"
      :class="border"
      class="align-middle"
    >
      <pp-skeleton-loader
        v-if="marketDetailsState.is('loading')"
        class="w-20 h-4 inline-block"
      ></pp-skeleton-loader>
      <pp-formatted-price
        v-else-if="marketDetailsState.is('resolved')"
        :value="underlyingValue"
        :min-decimal="5"
        :max-decimal="5"
        notation="compact"
        :data-test="`${market.address.toLowerCase()}-underlying-value`"
      ></pp-formatted-price>
      <pp-btn
        v-else-if="marketDetailsState.is('rejected')"
        @click="fetchMarketDetails"
        color="p-red"
        variant="accent"
      >
        Retry
      </pp-btn>

      <div>
        <pp-skeleton-loader
          v-if="marketDetailsState.is('loading')"
          class="w-28 h-2 inline-block"
        ></pp-skeleton-loader>
        <div v-else-if="marketDetailsState.is('resolved')" class="text-sm text-water-600">
          ~
          <pp-formatted-percent
            :value="underlyingYield"
            :min-decimal="2"
            :max-decimal="2"
            :data-test="`${market.address.toLowerCase()}-underlying-yield`"
          ></pp-formatted-percent>
          per year
        </div>
      </div>
    </pp-td>

    <pp-td align="right" :class="border">
      <pp-skeleton-loader
        v-if="tokenPriceState.is('loading')"
        class="w-20 h-4 inline-block"
      ></pp-skeleton-loader>
      <pp-formatted-price
        v-else-if="tokenPriceState.is('resolved')"
        :value="price"
        :min-decimal="5"
        :max-decimal="5"
        notation="compact"
        :data-test="`${market.address.toLowerCase()}-price`"
      ></pp-formatted-price>
      <pp-btn
        v-else-if="tokenPriceState.is('rejected')"
        @click="fetchTokenPrice"
        color="p-red"
        variant="accent"
      >
        Retry
      </pp-btn>

      <div>
        <pp-skeleton-loader
          v-if="marketDetailsState.is('loading')"
          class="w-28 h-2 inline-block"
        ></pp-skeleton-loader>
        <div v-else-if="marketDetailsState.is('resolved')" class="text-sm text-water-600">
          ~
          <pp-formatted-percent
            :value="impliedYield"
            :min-decimal="2"
            :max-decimal="2"
            :data-test="`${market.address.toLowerCase()}-implied-yield`"
          ></pp-formatted-percent>
          per year
        </div>
      </div>
    </pp-td>

    <pp-td align="right" :class="border">
      <pp-skeleton-loader
        v-if="marketDetailsState.is('loading')"
        class="w-20 h-4 inline-block"
      ></pp-skeleton-loader>
      <pp-formatted-price
        v-else-if="marketDetailsState.is('resolved')"
        :value="liquidity"
        :data-test="`${market.address.toLowerCase()}-liquidity`"
      ></pp-formatted-price>
      <pp-btn
        v-else-if="marketDetailsState.is('rejected')"
        @click="fetchMarketDetails"
        color="p-red"
        variant="accent"
      >
        Retry
      </pp-btn>
    </pp-td>

    <pp-td align="right" :class="border" :data-test="market.address.toLowerCase()">
      <item-actions :actions="actions"></item-actions>
    </pp-td>
  </pp-tr>
</template>

<script>
  import ItemActions from './ItemActions.vue'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import TokenAmount from '@/domains/entities/TokenAmount'

  export default {
    components: {
      ItemActions,
    },
    props: {
      market: { type: Object, required: true },
      rowspan: { type: Number, default: undefined },
      actions: { type: Array, default: () => [] },
      tokenType: { type: String, default: 'yt' },
      combined: { type: Boolean, default: false },
    },
    data() {
      return {
        tokenPriceState: createState({
          response: { currency: 'USD', amount: '0' },
        }),
        marketDetailsState: createState({
          response: {
            otherDetails: {
              impliedYield: '0',
              liquidity: { currency: 'USD', amount: '0' },
              underlyingPrice: { currency: 'USD', amount: '0' },
              underlyingYieldRate: 0,
            },
          },
        }),
      }
    },
    computed: {
      expiry() {
        return 1000 * this.market.expiryEpoch
      },
      underlyingValue() {
        return this.marketDetailsState.response.otherDetails.underlyingPrice.amount
      },
      underlyingYield() {
        return 100 * this.marketDetailsState.response.otherDetails.underlyingYieldRate
      },
      price() {
        return this.tokenPriceState.response.amount
      },
      impliedYield() {
        if (this.market.statusIs('expired')) {
          return 0
        }
        return 100 * this.marketDetailsState.response.otherDetails.impliedYield
      },
      liquidity() {
        return this.marketDetailsState.response.otherDetails.liquidity.amount
      },
      border() {
        return !this.rowspan && 'border-none'
      },
    },
    methods: {
      fetchTokenPrice() {
        const tokenAmount = new TokenAmount(this.market[this.tokenType], 1, false)
        new PromiseHandler(
          () => tokenAmount.contract(this.$store.getters['wallet/identity']).fetchValuation(),
          this.tokenPriceState
        ).execute()
      },
      fetchMarketDetails() {
        new PromiseHandler(
          () => this.market.contract(this.$store.getters['wallet/identity']).readMarketDetails(),
          this.marketDetailsState
        ).execute()
      },
      fetchTableData() {
        this.fetchTokenPrice()
        this.fetchMarketDetails()
      },
    },
    created() {
      this.fetchTableData()

      const interval = setInterval(this.fetchTableData, 60000)
      this.$once('hook:beforeDestroy', () => clearInterval(interval))
    },
  }
</script>
