<template>
  <div class="pt-8 pb-40 container mx-auto space-y-10">
    <MarketTableContainer />
  </div>
</template>

<script>
  import MarketTableContainer from '@/components/tables/market/MarketTableContainer.vue'

  export default {
    metaInfo: {
      title: 'Market',
    },
    components: {
      MarketTableContainer,
    },
  }
</script>
