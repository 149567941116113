<template>
  <div v-if="!combinedView">
    <pp-section
      header="YT Markets"
      tooltip="YT represents entitlement to the yield of the underlying asset where its holders receive the yield of the underlying yield-bearing asset."
      tooltip-direction="bottom"
    >
      <template v-slot:right>
        <TableViewToggle
          :combined="combinedView"
          @toggle="combinedView = !combinedView"
        />
      </template>
      <MarketTable tokenType="yt" :marketGroups="ytMarketGroups" />
    </pp-section>
    <ExpiredMarketSection title="Expired YT Markets" tokenType="yt" :marketGroups="expiredYtMarketGroups" />

    <pp-section
      header="OT Markets"
      tooltip="OT represents ownership of the underlying asset without the yield, essentially a zero-coupon bond of the underlying yield-bearing asset."
      tooltip-direction="bottom"
      class="mt-10"
    >
      <MarketTable tokenType="ot" :marketGroups="otMarketGroups" />
    </pp-section>
    <ExpiredMarketSection title="Expired OT Markets" tokenType="ot" :marketGroups="expiredOtMarketGroups" />
  </div>

  <div v-else>
    <pp-section
      header="Markets"
      tooltip-direction="bottom"
    >
      <template v-slot:tooltip>
        YT represents entitlement to the yield of the underlying asset where its holders receive the yield of the underlying yield-bearing asset.
        <br />
        <br />
        OT represents ownership of the underlying asset without the yield, essentially a zero-coupon bond of the underlying yield-bearing asset.
      </template>
      <template v-slot:right>
        <TableViewToggle
          :combined="combinedView"
          @toggle="combinedView = !combinedView"
        />
      </template>
      <MarketTable tokenType="combined" :marketGroups="combinedMarketGroups" />
    </pp-section>
    <ExpiredMarketSection title="Expired Markets" tokenType="combined" :marketGroups="expiredCombinedMarketGroups" />
  </div>
</template>

<script>
  import MarketTable from '@/components/tables/market/MarketTable.vue'
  import TableViewToggle from '@/components/TableViewToggle.vue'
  import ExpiredMarketSection from './ExpiredMarketSection.vue'
  import { getMarketTableData } from './queries'

  export default {
    components: {
      MarketTable,
      TableViewToggle,
      ExpiredMarketSection,
    },
    data() {
      return {
        combinedView: false,
        ytMarketGroups: getMarketTableData({ tokenType: 'yt' }),
        otMarketGroups: getMarketTableData({ tokenType: 'ot' }),
        combinedMarketGroups: getMarketTableData({ tokenType: 'combined' }),
        expiredYtMarketGroups: getMarketTableData({ tokenType: 'yt', expired: true }),
        expiredOtMarketGroups: getMarketTableData({ tokenType: 'ot', expired: true }),
        expiredCombinedMarketGroups: getMarketTableData({ tokenType: 'combined', expired: true }),
      }
    },
  }
</script>
